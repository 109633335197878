import React from 'react'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Container } from '../components/container'
import { Title } from '../components/title'

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Seite nicht gefunden" />
      <Container>
        <Title>Diese Seite wurde leider nicht gefunden.</Title>
      </Container>
    </Layout>
  )
}
